import React from 'react';
import {TextField, InputAdornment, IconButton} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {SearchSvg} from '../assets';

type Props = {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
};

function IconInput({searchQuery, setSearchQuery}: Props) {
  const {t} = useTranslation();
  return (
    <TextField
      size="small"
      type="text"
      onChange={e => setSearchQuery(e.target.value)}
      value={searchQuery}
      placeholder={t('search')}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton aria-label="search icon" edge="start">
              <img src={SearchSvg} className="size-4" />
            </IconButton>
          </InputAdornment>
        ),
      }}
      sx={{
        width: 300,
        '& .MuiOutlinedInput-root': {
          borderRadius: '100px',
          backgroundColor: 'white',
        },
      }}
    />
  );
}

export default IconInput;
