import React, {useCallback, useMemo} from 'react';
import {
  DataGrid,
  GridColDef,
  gridClasses,
  GridRowSpacingParams,
} from '@mui/x-data-grid';
import {Pagination} from '@mui/material';
import {styled} from '@mui/material/styles';
import CustomModal from '../../components/Modal';
import {useTranslation} from 'react-i18next';
interface Props {
  searchQuery: string;
  columns: GridColDef[];
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  deleteWithQuery: (id: string) => void;
  delFoodId: any;
  page: number;
  setPage: (page: number) => void;
  pageSize: number;
  setPageSize?: (pageSize: number) => void;
  foodItemsData: any;
}

const CustomPagination = styled(Pagination)(({theme}) => ({
  '& .MuiPaginationItem-root': {
    border: 'none',
  },
  '& .MuiPaginationItem-previousNext': {
    border: '1px solid #e0e0e0',
  },
  '& .MuiPaginationItem-root.Mui-selected': {
    color: 'white',
  },
  '& .MuiPaginationItem-root:hover': {
    color: 'white',
  },
}));

function FoodIngredientsTable({
  delFoodId,
  deleteWithQuery,
  columns,
  isModalOpen,
  setIsModalOpen,
  foodItemsData,
  page,
  setPage,
  pageSize,
}: Props) {
  const {t} = useTranslation();

  const users = foodItemsData?.data || [];
  const rowCount = foodItemsData?.total || 0;

  const startingIndex = useMemo(() => {
    return page * pageSize - pageSize + 1;
  }, [page, pageSize]);

  const endIndex = useMemo(() => {
    return Math.min(page * pageSize, foodItemsData?.total ?? 0);
  }, [page, pageSize, foodItemsData?.total]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
  };

  const getRowSpacing = useCallback((params: GridRowSpacingParams) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: params.isLastVisible ? 2 : 5,
    };
  }, []);
  const CustomFooter = () => {
    return (
      <div className="flex justify-between items-center py-4 px-2">
        <div>
          <p className="text-xs ">
            Showing {startingIndex ?? 0} to {endIndex ?? 0} of{' '}
            {foodItemsData?.total ?? 0} items
          </p>
        </div>
        <CustomPagination
          count={foodItemsData?.totalPages ?? 0}
          page={foodItemsData?.page ?? 0}
          onChange={handlePageChange}
          color="primary"
        />
      </div>
    );
  };

  return (
    <>
      <div>
        <DataGrid
          sx={{
            '& .MuiDataGrid-row': {
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: '#E7E7E7',
              borderRadius: '8px',
            },
            paddingInline: '20px',
            '--DataGrid-rowBorderColor': 'transparent',
            '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {py: '6px'},
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
              py: '12px',
            },
            '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
              py: '18px',
            },
            [`& .${gridClasses.columnHeader}, & .${gridClasses.cell}`]: {
              outline: 'transparent',
            },
            [`& .${gridClasses.columnHeader}:focus-within, & .${gridClasses.cell}:focus-within`]:
              {
                outline: 'none',
              },
          }}
          rows={users}
          columns={columns}
          disableRowSelectionOnClick
          disableColumnMenu
          rowCount={rowCount}
          paginationMode="server"
          // sortingMode="server"
          filterMode="server"
          getRowSpacing={getRowSpacing}
          getRowHeight={() => 'auto'}
          slots={{
            footer: CustomFooter,
          }}
        />
      </div>
      <CustomModal
        isModalOpen={isModalOpen && !!delFoodId}
        setIsModalOpen={setIsModalOpen}
        description={t('deleteFoodItem')}
        onClick={() => deleteWithQuery(delFoodId)}
      />
    </>
  );
}

export default FoodIngredientsTable;
