import {Autocomplete, CircularProgress, TextField} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
// import {users} from '../constants/clients';
type Props = {
  title: string;
  subtitle: string;
  firstBtnTxt?: string;
  secondBtnTxt?: string;
  firstBtnIcon?: any;
  secondBtnIcon?: any;
  isDashboard?: boolean;
  users?: any[];
  handleSubmit?: any;
  handleUserChange?: any;
  selectedUser?: any;
  handleSubmitSecond?: (data: any) => void;
  firstBtnLoading?: boolean;
  secondBtnLoading?: boolean;
};

function Header({
  title,
  subtitle,
  firstBtnTxt,
  secondBtnTxt,
  firstBtnIcon,
  secondBtnIcon,
  isDashboard,
  users,
  handleSubmit,
  handleUserChange,
  selectedUser,
  handleSubmitSecond,
  firstBtnLoading,
  secondBtnLoading,
}: Props) {
  const {t} = useTranslation();
  return (
    <div className="flex items-center justify-between">
      <div>
        <h1 className="text-h1 font-medium text-dark-text">{title}</h1>
        <p className="text-gray-text font-[400] text-[16px] ">{subtitle}</p>
      </div>
      <div className="flex items-center gap-4">
        {firstBtnTxt && (
          <button
            onClick={handleSubmit}
            className="bg-[#000000] text-white text-[16px] font-medium px-12 py-4 rounded-xl flex gap-4">
            {firstBtnLoading ? (
              <CircularProgress size={25} sx={{color: 'white'}} />
            ) : (
              <>
                {firstBtnTxt}
                {firstBtnIcon && (
                  <img src={firstBtnIcon} alt="FirstIcon" className="w-6 h-6" />
                )}
              </>
            )}
          </button>
        )}
        {secondBtnTxt && (
          <button
            onClick={handleSubmitSecond}
            className="bg-white text-dark-text text-[16px] font-medium px-12 py-4 rounded-xl border-[1.2px] border-gray-text flex gap-4">
            {secondBtnLoading ? (
              <CircularProgress size={25} sx={{color: 'black'}} />
            ) : (
              <>
                {secondBtnTxt}
                {secondBtnIcon && (
                  <img
                    src={secondBtnIcon}
                    alt="SecondIcon"
                    className="w-6 h-6"
                  />
                )}
              </>
            )}
          </button>
        )}
      </div>
      {isDashboard && (
        <div className="flex items-center gap-6">
          <Autocomplete
            disablePortal
            size="small"
            value={selectedUser ?? ''}
            getOptionLabel={option =>
              option ? `${option.fname} ${option.lname}` : ''
            }
            onChange={handleUserChange}
            options={users ?? []}
            renderInput={params => (
              <TextField
                {...params}
                label="Users"
                sx={{
                  width: 300,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '100px',
                  },
                }}
              />
            )}
          />
          <img
            src={
              selectedUser?.profile_photo ??
              'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdpRvftRBgfCbvzOHB0bANVih3QvZD-xZ4flbABUFGDctmaY87ajkJD5RhdvVcyZvkS7U&usqp=CAU'
            }
            alt="user-profile-img"
            className="size-[48px] rounded-full"
          />
        </div>
      )}
    </div>
  );
}

export default Header;
