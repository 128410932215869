import React from 'react';
import {Modal, Box, Button, Typography} from '@mui/material';
import DustbinLg from '../assets/dustbinLg.svg';
import {useTranslation} from 'react-i18next';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: '#ffffff',
  border: '1px solid #9C9EB9',
  boxShadow: 24,
  borderRadius: '12px',
  px: 8,
  py: 4,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};
interface Props {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  description?: string;
  onClick?: any;
}
const CustomModal = ({
  isModalOpen,
  setIsModalOpen,
  description,
  onClick,
}: Props) => {
  const {t} = useTranslation();
  const handleClose = () => setIsModalOpen(false);
  const handleDelete = () => {
    onClick();
    setIsModalOpen(false);
  };
  return (
    <div>
      <Modal
        open={isModalOpen}
        onClose={handleClose}
        sx={{
          border: 'none',
        }}
        aria-labelledby="modal-title"
        aria-describedby="modal-description">
        <Box sx={style}>
          <img src={DustbinLg} alt="dustbin" />
          <Typography
            id="modal-description"
            sx={{mt: 4, fontSize: 20, fontWeight: 500, textAlign: 'center'}}>
            {description ?? t('deleteNotification')}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 4,
              mt: 4,
            }}>
            <Button
              onClick={handleClose}
              variant="outlined"
              sx={{
                borderRadius: '8px',
                border: '1px solid #9C9EB9',
                color: '#000000',
                fontWeight: 500,
                fontSize: 16,
                paddingInline: 5,
                paddingBlock: 1.5,
              }}>
              {t('cancel')}
            </Button>
            <Button
              onClick={handleDelete}
              variant="contained"
              sx={{
                borderRadius: '8px',
                backgroundColor: '#000000',
                color: '#FFFFFF',
                fontWeight: 500,
                fontSize: 16,
                paddingInline: 5,
                paddingBlock: 1.5,
              }}>
              {t('delete')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default CustomModal;
