import React, {useCallback, useState} from 'react';
import Header from '../../components/Header';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {Checkbox, ListItemText, Menu, MenuItem} from '@mui/material';
import * as XLSX from 'xlsx';
import ClientsTable from './clientsTable';
import {useTranslation} from 'react-i18next';
import {ClientSubHeader} from '../../types';
import Move from '../../assets/move.png';
import Water from '../../assets/water.png';
import Nutrition from '../../assets/nutrition.png';
import Weight from '../../assets/weight.png';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import {useNavigate} from 'react-router-dom';
import {
  deleteUser,
  fetchUsers,
  handlePlanAssociation,
} from '../../services/clients';
import Loader from '../../components/Loader';
import {
  ImportSvg,
  ExportSvg,
  SearchSvg,
  ColSelectSvg,
  EditSvg,
  BasketSvg,
  DashboardSvg,
} from '../../assets';
import {handleExport, downloadBlob} from '../../services/importAndExport';
import {routes} from '../../constants/routes';
import {useDebounce} from '../../services/debounce';
import DashboardModal from './DashboardModal';
import {handleFetchPlans} from '../../services/plans';
import i18n from '../../i18n';
import IconInput from '../../components/IconInput';
import TrainerSelector from '../../components/TrainerSelector';
type Props = {};

function Index({}: Props) {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const clientsSubHeaders: ClientSubHeader[] = [
    {
      id: 1,
      key: 'move',
      label: t('move'),
      header: '1,578',
      icon: Move,
    },
    {
      id: 2,
      key: 'water',
      label: t('water'),
      header: '8',
      icon: Water,
    },
    {
      id: 3,
      key: 'nutrition',
      label: t('nutrition'),
      header: '1,578',
      icon: Nutrition,
    },
    {
      id: 4,
      key: 'weight',
      label: t('weight'),
      header: '81',
      icon: Weight,
    },
  ];
  const columns: GridColDef[] = [
    {
      field: 'profile_photo',
      headerName: t('picture'),
      sortable: false,
      disableColumnMenu: true,
      width: 70,
      renderCell: (params: GridRenderCellParams<any>) => (
        <div className="flex items-center justify-center">
          <div className="rounded-full overflow-hidden w-10 h-10">
            <img
              src={params.value}
              alt="img"
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      ),
    },
    {
      field: 'fname',
      headerName: t('firstname'),
      width: 200,
    },
    {
      field: 'lname',
      headerName: t('lastname'),
      width: 200,
    },
    {
      field: 'phone_number',
      headerName: t('phone'),
      width: 200,
    },
    {
      field: 'age',
      headerName: t('age'),
      width: 160,
    },
    {
      field: 'current_weight',
      headerName: t('currentweight'),
      width: 160,
    },
    {
      field: 'nutrition_plan',
      headerName: t('planAssigned'),
      width: 200,
      renderCell: (params: GridRenderCellParams<any>) =>
        params.value ? (
          <div className="text-center border border-border-color rounded-full">
            <p className="text-xs font-[400] py-1 px-8">
              {params.value.plan_name ?? ''}
            </p>
          </div>
        ) : (
          ''
        ),
    },
    {
      field: 'actions',
      headerName: t('actions'),
      width: 200,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any>) => (
        <div className="flex items-center gap-4">
          <img
            src={DashboardSvg}
            alt="Dashboard"
            onClick={() => handleAssociation(params.row.id)}
            className="cursor-pointer"
          />
          <img
            src={EditSvg}
            alt="Edit"
            onClick={() => handleEdit(params.row)}
            className="cursor-pointer"
          />
          <img
            src={BasketSvg}
            alt="Delete"
            onClick={() => handleDelete(params.row.id)}
            className="cursor-pointer"
          />
        </div>
      ),
    },
  ];
  const [tableColumns, setTableColumns] = useState(
    columns.map(col => col.field),
  );
  const queryClient = useQueryClient();
  const [searchQuery, setSearchQuery] = useState('');
  const [delUserId, setDelUserId] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [nutrionistPlan, setNutrionistPlan] = useState<any>([]);
  const [selectedPlan, setSelectedPlan] = useState<any>({
    id: null,
    plan_name: 'Select Plan',
  });
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [isAssociateModalOpen, setIsAssociateModalOpen] =
    useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(4);
  const debouncedSearch = useDebounce(searchQuery, 300);
  const [selectedTrainerId, setSelectedTrainerId] = useState<any>(
    localStorage.getItem('user_type') === '1' ? 23 : null,
  );
  const {
    data: usersData,
    error: usersError,
    isLoading: usersLoading,
  } = useQuery(
    ['users', page, pageSize, debouncedSearch, selectedTrainerId],
    () => fetchUsers(page, pageSize, debouncedSearch, selectedTrainerId),
    {
      keepPreviousData: true,
    },
  );

  const {
    mutateAsync: deleteWithQuery,
    isLoading: isDeleting,
    isError,
  } = useMutation(deleteUser, {
    onSuccess: data => {
      queryClient.invalidateQueries(['users']);
      setIsModalOpen(false);
      if (data) {
        alert('User deleted successfully');
      }
    },
    onError: () => {
      console.error('Error deleting user');
      alert('Error deleting user');
    },
  });

  const {
    data: plans,
    isLoading: isLoadingPlans,
    isError: isPlanError,
  } = useQuery(['nutritionistPlans'], () => handleFetchPlans(1, 100, ''), {
    keepPreviousData: true,
    onSuccess: data => {
      if (data.data) {
        setNutrionistPlan(data.data);
      }
    },
  });

  const {
    mutateAsync: exportData,
    isLoading: isExporting,
    isError: isExportError,
  } = useMutation(handleExport, {
    onSuccess: data => {
      downloadBlob(data, 'users_exported_file.csv');
    },
  });

  const {
    mutateAsync: associateUser,
    isLoading: isAssociating,
    isError: isAssociateError,
  } = useMutation(handlePlanAssociation, {
    onSuccess: data => {
      queryClient.invalidateQueries(['users']);
      if (data) {
        alert('User Associated successfully');
        setSelectedPlan(null);
        setSelectedUser(null);
      }
    },
    onError: () => {
      console.error('Error adding user');
      alert('Error associating user');
    },
  });

  const handleEdit = (user: any) => {
    navigate(`/addNewClient/${user.id}`);
  };
  const handleDelete = (userId: number) => {
    setDelUserId(userId);
    setIsModalOpen(true);
  };

  const handleAssociation = (userId: number) => {
    setIsAssociateModalOpen(true);
    setSelectedUser(userId);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = (option: any) => {
    const field = option.field;
    if (tableColumns.includes(field)) {
      setTableColumns(prevState => prevState.filter(item => item !== field));
    } else {
      setTableColumns(prevState => [...prevState, field]);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = e => {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, {type: 'array'});
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        console.log('Imported users are ==>:', jsonData);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handlePlanChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: any | null) => {
      setSelectedPlan(newValue ? newValue : null);
    },
    [setSelectedPlan],
  );

  const associateUserWithPlan = async () => {
    try {
      const userId = selectedUser ?? 0;
      const selectedPlanId = selectedPlan?.id ?? 0;

      if (!selectedPlanId || !userId) {
        return;
      }

      await associateUser({userId, selectedPlanId});
    } catch (error) {
      console.error('Error associating user with plan:', error);
      alert('Error in associating user with plan');
    }
  };

  if (isDeleting || usersLoading || isLoadingPlans || isAssociating) {
    return <Loader />;
  }
  console.log('selected trainer', selectedTrainerId);
  if (
    isError ||
    isExportError ||
    usersError ||
    isPlanError ||
    isAssociateError
  ) {
    return <div>Error</div>;
  }
  return (
    <>
      <div className="bg-light-green min-h-[100vh] p-8">
        <Header
          title={t('clientheader')}
          subtitle={t('clientsubheader')}
          firstBtnTxt={t('import')}
          firstBtnIcon={ImportSvg}
          secondBtnTxt={t('export')}
          secondBtnIcon={ExportSvg}
          handleSubmitSecond={() => exportData({route: routes.EXPORT_CLIENTS})}
          handleSubmit={() =>
            document.getElementById('xlsxUploadInput')?.click()
          }
          secondBtnLoading={isExporting}
        />
        <input
          id="xlsxUploadInput"
          type="file"
          accept=".csv"
          onChange={handleFileChange}
          style={{display: 'none'}}
        />
        {/* subheader */}
        <div className="flex items-center justify-between mt-8">
          {/* left div */}
          <div className="flex gap-3">
            {clientsSubHeaders?.map(client => (
              <div
                key={client.key}
                className={`bg-white flex gap-2 ${
                  i18n.language == 'en' ? 'pr-6' : 'pl-6'
                } items-center rounded-[12px] box-border py-2`}>
                <img src={client.icon} alt={client.key} className="-mt-4" />
                <div className="flex flex-col  bg-white">
                  <p className="font-[700] text-[13px] text-dark-text">
                    {client?.header}
                  </p>
                  <p className="font-[400] text-sm text-gray-text">
                    {client?.label}
                  </p>
                </div>
              </div>
            ))}
          </div>
          {/* right div*/}
          <div className="flex gap-4">
            {localStorage.getItem('user_type') === '1' && (
              <TrainerSelector
                selectedTrainerId={selectedTrainerId}
                setSelectedTrainerId={setSelectedTrainerId}
              />
            )}
            <IconInput
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />

            <div
              onClick={handleClick}
              className="flex items-center gap-2 hover:cursor-pointer">
              <img src={ColSelectSvg} alt="sort" className="size-4" />
              <p className="text-dark-text text-[16px]">{t('selectcol')}</p>
            </div>
            <div>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                {columns.map((option: any) => (
                  <MenuItem key={option.field}>
                    <Checkbox
                      checked={tableColumns.includes(option.field)}
                      onChange={() => handleToggle(option)}
                    />
                    <ListItemText primary={option.headerName} />
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </div>
        </div>
        {/* clients table */}
        <div className="mt-4">
          <ClientsTable
            searchQuery={searchQuery}
            columns={columns.filter(col => tableColumns.includes(col.field))}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            deleteWithQuery={deleteWithQuery}
            delUserId={delUserId}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            usersData={usersData}
          />
        </div>
      </div>
      <DashboardModal
        isModalOpen={isAssociateModalOpen}
        setIsModalOpen={setIsAssociateModalOpen}
        plans={nutrionistPlan}
        handlePlanChange={handlePlanChange}
        selectedPlan={selectedPlan}
        onClick={() => associateUserWithPlan()}
      />
    </>
  );
}

export default Index;
