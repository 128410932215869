import {CircularProgress} from '@mui/material';
import React from 'react';

type Props = {};

function Loader({}: Props) {
  return (
    <div className="w-full h-[100vh] flex items-center justify-center">
      <CircularProgress color="success" />
    </div>
  );
}

export default Loader;
