import React from 'react';
import logoImage from '../assets/logo.png'; 

const Logo = () => {
  return (
    <img src={logoImage} alt="Company Logo" className="h-28 w-28 ml-16"/> 
  );
};

export default Logo;
