import React from 'react';
import {Sun, Degree} from '../../assets';
type Props = {};

function Temperature({}: Props) {
  return (
    <div>
      <div className="flex items-center justify-center gap-4">
        <img src={Sun} alt="sun" />
        <p className="text-4xl font-medium flex">
          26{' '}
          <sup>
            {' '}
            <img src={Degree} alt="degree" />
          </sup>{' '}
          C
        </p>
      </div>
    </div>
  );
}

export default Temperature;
