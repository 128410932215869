import {useState, useEffect} from 'react';

export const useDebounce = (value: any, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

// //@ts-ignore
// const debounce = (func, delay) => {
//     //@ts-ignore
//     let timeoutId;
//     //@ts-ignore
//     return (...args) => {
//       //@ts-ignore
//       clearTimeout(timeoutId);
//       timeoutId = setTimeout(() => {
//         func.apply(null, args);
//       }, delay);
//     };
//   }
