import React from 'react';

interface ButtonProps {
  label: string | React.ReactNode;
  className?: string;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  label,
  className = '',
  onClick,
  type = 'button',
  disabled = false,
}) => {
  return (
    <button
      className={`bg-gradient-to-r from-custom-blue to-custom-green text-white font-bold py-3  px-28 rounded-full flex items-center gap-2 ${className}`}
      onClick={onClick}
      type={type}
      disabled={disabled}>
      {label}
    </button>
  );
};

export default Button;
