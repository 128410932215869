import React, {useEffect, useState} from 'react';
import EditClients from './editClients';
import Header from '../../components/Header';
import {useTranslation} from 'react-i18next';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useParams} from 'react-router-dom';
import {
  handleClientAdd,
  handleClientEdit,
  fetchClient,
} from '../../services/clients';

function Index() {
  const {t} = useTranslation();
  const {userId} = useParams();
  const queryClient = useQueryClient();
  const [selectedTrainerId, setSelectedTrainerId] = useState<any>();
  const [client, setClient] = useState({
    profile_photo: '',
    user_type: 3,
    active: 0,
    fname: '',
    lname: '',
    phone_number: '',
    email: '',
    gender: '',
    birthdate: '',
    current_weight: '',
    height: '',
    water_reminder: 0,
    food_reminder: 0,
    steps_reminder: 0,
    activity_reminder: 0,
    weight_reminder: 0,
    user_daily_goal_nutrition: '',
    user_daily_goal_water: '',
    user_daily_goal_activity: '',
    user_daily_goal_weight: '',
    user_daily_goal_steps: '',
    fitness_level: '',
    target_weight: '',
    time_to_sleep: '',
    health_need: '',
    health_issues: '',
    health_status: '',
    blood_pressure: '',
    medical_recommend: '',
    pregnant: '',
    breastfeeding: '',
    exercise_limitation: '',
    food_preferences: '',
    food_sensitivity: '',
    food_common_method: '',
    is_vegetarian: 0,
    is_vegan: 0,
    is_fruity: 0,
  });

  const {
    data: editClient,
    error: fetchError,
    isLoading: isFetching,
  } = useQuery(['client', userId], () => fetchClient(Number(userId)), {
    enabled: !!userId,
    onSuccess: data => {
      console.log('edited successfully');
      queryClient.invalidateQueries(['users']);
    },
  });

  useEffect(() => {
    if (editClient) {
      setClient(editClient);
    }
  }, [editClient]);

  const {
    mutateAsync: addClient,
    isLoading: isAdding,
    isError: isAddError,
  } = useMutation(handleClientAdd, {
    onSuccess: () => {
      setClient({
        profile_photo: '',
        user_type: 3,
        active: 0,
        fname: '',
        lname: '',
        phone_number: '',
        email: '',
        gender: '',
        birthdate: '',
        current_weight: '',
        height: '',
        water_reminder: 0,
        food_reminder: 0,
        steps_reminder: 0,
        activity_reminder: 0,
        weight_reminder: 0,
        user_daily_goal_nutrition: '',
        user_daily_goal_water: '',
        user_daily_goal_activity: '',
        user_daily_goal_weight: '',
        user_daily_goal_steps: '',
        fitness_level: '',
        target_weight: '',
        time_to_sleep: '',
        health_need: '',
        health_issues: '',
        health_status: '',
        blood_pressure: '',
        medical_recommend: '',
        pregnant: '',
        breastfeeding: '',
        exercise_limitation: '',
        food_preferences: '',
        food_sensitivity: '',
        food_common_method: '',
        is_vegetarian: 0,
        is_vegan: 0,
        is_fruity: 0,
      });
      setSelectedTrainerId(null);
      console.log('Client added successfully');
    },
    onError: error => {
      console.error('Error adding client:', error);
    },
  });

  const {
    mutateAsync: editClientData,
    isLoading: isEditing,
    isError: isEditError,
  } = useMutation(handleClientEdit, {
    onSuccess: () => {
      console.log('Client edited successfully');
    },
    onError: error => {
      console.error('Error editing client:', error);
    },
  });

  const handleSubmit = async () => {
    if (userId) {
      await editClientData({client, userId});
    } else {
      if (!selectedTrainerId && localStorage.getItem('user_type') === '1')
        return alert('please select trainer');
      await addClient({client, trainer_id: selectedTrainerId});
    }
  };

  if (fetchError || isAddError || isEditError) {
    return <div>Error while fetching and adding/editing client</div>;
  }
  return (
    <div className="bg-light-green min-h-[100vh] p-8">
      <Header
        title={t('addeditclient')}
        subtitle={t('addclientsubheading')}
        firstBtnTxt={t('save')}
        handleSubmit={handleSubmit}
        firstBtnLoading={isAdding || isEditing}
      />
      <EditClients
        client={client}
        setClient={setClient}
        selectedTrainerId={selectedTrainerId}
        setSelectedTrainerId={setSelectedTrainerId}
        isAddingClient
      />
    </div>
  );
}

export default Index;
