export const routes = {
  SEND_OTP: '/auth/send-otp',
  LOGIN: '/auth/login',
  ME: '/auth/me',
  USERS: '/users',
  NUTRITION_PLANS: '/nutrition-plans',
  SEND_PUSH_NOTIFICATION: '/users/send-message',
  EXPORT_NUTRITION_PLANS: '/nutrition-plans/export',
  EXPORT_ARTICLES: '/articles/export',
  EXPORT_CLIENTS: '/users/export',
  FOOD_INGREDIENTS: '/food-ingredients',
  EXPORT_FOOD_INGREDIENTS: '/food-ingredients/export',
  RECIPES: '/recipes',
  EXPORT_RECIPES: '/recipes/export',
};
