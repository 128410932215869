import {Autocomplete, TextField} from '@mui/material';
import React from 'react';
import {useQuery} from 'react-query';
import {fetchTrainers} from '../services/trainers';
import Loader from './Loader';

type Props = {
  selectedTrainerId: number | string | null;
  setSelectedTrainerId?: (val: number | string | null | any) => void;
  width?: number | string;
  borderRadius?: string;
  isShowLabel?: boolean;
};

function TrainerSelector({
  selectedTrainerId,
  setSelectedTrainerId,
  width,
  borderRadius,
  isShowLabel = true,
}: Props) {
  const {
    data: trainersData,
    error: trainersError,
    isLoading: trainersLoading,
  } = useQuery(['trainers'], () => fetchTrainers(1, 100, ''), {
    keepPreviousData: true,
  });
  const handleTrainerChange = (
    event: React.ChangeEvent<{}>,
    newValue: any | null,
  ) => {
    if (setSelectedTrainerId) {
      setSelectedTrainerId(newValue ? newValue.id : null);
    }
  };

  if (trainersError) {
    return <div>error while fetching trainers</div>;
  }
  if (trainersLoading) {
    return <Loader />;
  }

  return (
    <Autocomplete
      disablePortal
      size="small"
      value={trainersData?.data?.find(
        (trainer: any) => trainer.id === selectedTrainerId,
      )}
      getOptionLabel={(option: any) =>
        option ? `${option.fname} ${option.lname}` : ''
      }
      onChange={handleTrainerChange}
      options={trainersData.data ?? []}
      renderInput={params => (
        <TextField
          {...params}
          {...(isShowLabel && {label: 'Trainer'})}
          sx={{
            width: width ?? 300,
            '& .MuiOutlinedInput-root': {
              borderRadius: borderRadius ?? '100px',
              border: 'none',
              backgroundColor: 'white',
            },
          }}
        />
      )}
    />
  );
}

export default TrainerSelector;
