import React from 'react';
import {CircularProgressbarWithChildren} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {useTranslation} from 'react-i18next';
type Props = {
  cal: any;
};

function CircularProgress({cal}: Props) {
  const {t} = useTranslation();
  return (
    <div className="size-[190px]">
      <CircularProgressbarWithChildren
        value={cal}
        styles={{
          root: {},
          path: {
            stroke: 'url(#gradient)', // Apply the gradient as stroke
            strokeLinecap: 'butt',
            transition: 'stroke-dashoffset 0.5s ease 0s',
            transformOrigin: 'center center',
          },
          trail: {
            stroke: '#d6d6d6',
            strokeLinecap: 'butt',
            transform: 'rotate(0.25turn)',
            transformOrigin: 'center center',
          },
        }}>
        <p className="text-[16px] -mt-1">{t('total')}</p>
        <p className="font-medium text-[28px] -mt-1">
          {Math.round(cal)}
          <span className="text-[20px]">{t('Cal')}</span>
        </p>
      </CircularProgressbarWithChildren>
      {/* SVG for gradient definition */}
      <svg height="0" width="0">
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" style={{stopColor: '#C337B5', stopOpacity: 1}} />
            <stop
              offset="100%"
              style={{stopColor: '#5046C0', stopOpacity: 1}}
            />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}

export default CircularProgress;
