import React, {useEffect, useState} from 'react';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import Logo from './assets/logo.png';
import Routes from './routes';
import Navbar from './components/Navbar';
import {BrowserRouter as Router} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Login from './pages/login';
import Loader from './components/Loader';

const theme = createTheme({
  palette: {
    primary: {
      main: '#7ECD93',
    },
  },
});

const App: React.FC = () => {
  const {i18n} = useTranslation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const currentLang = i18n.language;
  //   document.documentElement.lang = currentLang;
  //   document.documentElement.dir = currentLang === 'he' ? 'rtl' : 'ltr';
  // }, [i18n.language]);

  useEffect(() => {
    const token = localStorage.getItem('userToken');
    setIsAuthenticated(!!token);
    setLoading(false);

    // verifyUsersToken();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('userToken');
    localStorage.removeItem('user_type');
    localStorage.removeItem('isSuperAdmin');
    setIsAuthenticated(false);
  };

  // const verifyUsersToken = async () => {
  //   console.log('Verifying users token');
  //   try {
  //     const response = await api.get(`${routes.ME}`);
  //     console.log('response==>', response);
  //   } catch (err) {
  //     console.log('Error verifying PROFILE', err);
  //   }
  // };

  if (loading) return <Loader />;
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <div className="App">
          {isAuthenticated ? (
            <div className="w-full h-screen bg-light-green flex">
              <div className="bg-white h-full w-[16%] z-20 shadow-lg overflow-y-auto">
                <div className="flex items-center justify-center">
                  <img src={Logo} alt="logo" className="w-[100px] h-auto" />
                </div>
                <Navbar onLogout={handleLogout} />
              </div>
              <div className="h-full w-[84%] overflow-auto">
                <Routes />
              </div>
            </div>
          ) : (
            <Login setIsAuthenticated={setIsAuthenticated} />
          )}
        </div>
      </ThemeProvider>
    </Router>
  );
};

export default App;
