import {api} from '../axiosInstance';

export const handleExport = async ({route}: any) => {
  const response = await api.get(`${route}`, {
    responseType: 'blob',
  });
  return response.data;
};

export const downloadBlob = (blob: any, filename: any) => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};
