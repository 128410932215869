import moment from 'moment';
import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 12000,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: -13000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: -6000,
    pv: -9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 12000,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: -1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: -3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];
type Props = {workoutEntries: any};

function StackedBarChart({workoutEntries}: Props) {
  // Check if weight entries are available
  if (workoutEntries.length === 0) {
    return null;
  }

  // Map the weight entries to the format needed by the chart
  const chartData = workoutEntries.map((entry: any) => ({
    name: moment(entry.datetime).format('DD/MM/YY'), // Format datetime as DD/MM/YY
    workout: entry.lifestyle_value, // lifestyle_value to be shown on Y axis
  }));
  return (
    <div className="h-[133px] w-[100%] mt-2">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={chartData}
          margin={{
            top: 20,
            bottom: 5,
          }}
          barSize={18}>
          {/* <XAxis dataKey="name" />
          <YAxis /> */}
          <Tooltip />
          <Bar dataKey="name" stackId="a" fill="#85AF3D" />
          <Bar dataKey="workout" stackId="a" fill="white" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default StackedBarChart;
