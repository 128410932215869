import moment from 'moment';
import React from 'react';
import {
  LineChart,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Dot,
} from 'recharts';

type Props = {stepsEntries: any};

const data = [
  {name: 'Page A', pv: 1000},
  {name: 'Page B', pv: 1000},
  {name: 'Page C', pv: 1300},
  {name: 'Page D', pv: -400},
  {name: 'Page E', pv: 1800},
  {name: 'Page f', pv: -2000},
  {name: 'Page g', pv: 5000},
  {name: 'Page h', pv: -5000},
  {name: 'Page i', pv: 2000},
  {name: 'Page j', pv: -1400},
  {name: 'Page k', pv: 1800},
  {name: 'Page l', pv: 1000},
  {name: 'Page m', pv: 1000},
];

function GradientStrokeLineChart({stepsEntries}: Props) {
  // Check if weight entries are available
  if (stepsEntries.length === 0) {
    return null;
  }

  // Map the weight entries to the format needed by the chart
  const chartData = stepsEntries.map((entry: any) => ({
    name: moment(entry.datetime).format('DD/MM/YY'), // Format datetime as DD/MM/YY
    steps: entry.lifestyle_value, // lifestyle_value to be shown on Y axis
  }));
  return (
    <div className="h-[123px] w-[80%]">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart width={300} height={100} data={chartData}>
          <defs>
            <linearGradient
              id="gradientStroke"
              x1="0%"
              y1="0%"
              x2="100%"
              y2="100%">
              <stop
                offset="0%"
                style={{stopColor: '#F6F6F6', stopOpacity: 1}}
              />
              <stop
                offset="100%"
                style={{stopColor: '#FF7C00', stopOpacity: 1}}
              />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" hide />
          <YAxis hide />
          <Line
            type="monotone"
            dataKey="steps"
            stroke="url(#gradientStroke)"
            activeDot={{r: 8}}
            strokeWidth={1.5}
            dot={true}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default GradientStrokeLineChart;
