import {routes} from '../../constants/routes';
import {api} from '../axiosInstance';

export const fetchTrainers = async (
  page: number,
  limit: number,
  debouncedSearch: string,
) => {
  const response = await api.get(
    `${routes.USERS}?page=${page}&limit=${limit}&sortKey=age&sortDirection=ASC&search=${debouncedSearch}&skipClients=true`,
  );
  return response.data;
};

export const handleTrainerAdd = async (trainer: any) => {
  const {created_by, nutrition_plan, ...filteredTrainer} = trainer;
  const {fname, lname, phone_number, birthdate, current_weight} =
    filteredTrainer;
  if (!fname || !lname || !phone_number || !birthdate || !current_weight) {
    return alert('Please fill all required fields');
  }
  const response = await api.post('/users', {users: [{...filteredTrainer}]});
  return response.data;
};

export const handleTrainerEdit = async ({userId, trainer}: any) => {
  const {created_by, nutrition_plan, ...filteredTrainer} = trainer;
  const response = await api.put(`/users/${Number(userId)}`, {
    ...filteredTrainer,
  });
  return response.data;
};

export const fetchTrainer = async (userId: any) => {
  const response = await api.get(`/users/${userId}`);
  return response.data;
};
