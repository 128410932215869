import React, {useState} from 'react';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import {
  Calendar,
  DayRange as CalendarDayRange,
} from '@hassanmojab/react-modern-calendar-datepicker';

type Props = {
  selectedDayRange: CalendarDayRange;
  setSelectedDayRange: Function;
};

const App: React.FC<Props> = ({selectedDayRange, setSelectedDayRange}) => {
  // const defaultValue: CalendarDayRange = {
  //   from: {year: 2019, month: 4, day: 16},
  //   to: {year: 2019, month: 4, day: 19},
  // };

  // const [selectedDayRange, setSelectedDayRange] =
  //   useState<CalendarDayRange>(defaultValue);
  // console.log('selecteddaterange', selectedDayRange);
  return (
    <div className="w-full h-auto pt-2 flex items-center justify-center">
      <Calendar
        calendarClassName="custom-calendar"
        value={selectedDayRange}
        onChange={(value: CalendarDayRange) => setSelectedDayRange(value)} // Make sure value matches the type from the library
        colorPrimary="#161616"
        colorPrimaryLight="rgba(239, 239, 239, 1)"
        shouldHighlightWeekends
      />
    </div>
  );
};

export default App;
