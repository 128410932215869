// src/i18n.ts
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: {
    he: {
      translation: {
        dashboard: 'לוּחַ מַחווָנִים',
        clients: 'לקוחות',
        addnewclient: 'הוסף לקוח חדש',
        nutritionistplans: 'תפריטי תזונה',
        addnewplan: 'הוסף תפריט תזונה',
        // articles: 'מאמרים',
        addnewarticle: 'הוסף מאמר חדש',
        logout: 'התנתק',

        dashboardtitle: 'עידן בכר',
        dashboardsubtitle: 'הדשבורד של עידן בכר',
        nutrition: 'צריכת מזון',
        calories: 'קלוריות',
        protein: 'חלבונים',
        // fat: 'שומנים',
        Carbs: 'פחמימות',

        waterintake: 'מים',
        glasses: 'כוסות',

        sendapushmessge: 'שלח הודעת פוש',
        weightprogress: 'התקדמות במשקל',
        progress: 'משקל נוכחי',
        goal: 'משקל יעד (78 קג’)',
        lastweight: 'משקל אחרון',
        kg: 'קג',
        caloriesburned: 'קלוריות שנשרפו',
        Cal: 'קל',
        stepstaken: 'צעדים',
        steps: 'צעדים',
        workoutname: 'שם האימון',
        functionalworkout: 'אימון פונקציונאלי',
        activity: 'פעילות',
        date: '21 ינואר, 2024',
        adminname: 'עידן בכר',
        send: 'שלח',
        month: 'אוגוסט',

        signup: 'הירשם למערכת',
        continue: 'הירשם',

        enterverficationcode: 'הזן קוד אימות',
        notrecieved: 'לא קיבלת?',
        resend: 'שולח שוב בעוד',
        privacypolicy: 'תקנון | מדיניות פרטיות',

        clientsubheader: 'בואו נתחיל לחיות בריא מעכשיו',
        clientheader: 'לקוחות',

        move: 'צעדים',
        water: 'מים',
        // nutrition: 'תזונה',
        weight: 'משקל',
        search: 'חיפוש',
        selectcol: 'בחר עמודות',

        firstname: 'שם פרטי',
        lastname: 'שם משפחה',
        phone: 'טלפון',
        age: 'גיל',
        // currentweight: 'משקל נוכחי',
        planassigned: 'תפריט תזונה',
        actions: 'פעולות',
        import: 'ייבוא',
        export: 'ייצוא',

        showing: 'מציג 1 עד 8 מתוך 40 פריטים',
        delete: 'מחק',
        cancel: 'בטל',
        deleteNotification: 'האם אתה בטוח שאתה רוצה למחוק את המשתמש הזה?',
        deletePlanNotification: 'האם אתה בטוח שברצונך למחוק את התוכנית הזו?',
        addeditclient: 'הוסף/ערוך לקוח',
        addclientsubheading: 'אל תשכח לשמור לאחר העדכון.',
        // personalinfo: 'מידע אישי',
        userphoto: 'תמונת פרופיל',
        status: 'סטָטוּס',
        phonenumber: 'מספר טלפון',
        email: 'כתובת מייל',
        birthdate: 'תאריך לידה',
        currentweight: 'משקל נוכחי',
        height: 'גובה',
        preferences: 'העדפות ויעדים',
        reminders: 'תזכורות',
        food: 'מזון',

        dailygoalnutrition: ' תזונה מטרה יומית של המשתמש',
        dailygoalwater: ' מי יעד יומי משתמש',
        dailygoalactivity: ' פעילות יעד יומית של המשתמש',
        dailygoalweight: ' משקל יעד יומי של המשתמש',
        dailygoalsteps: 'שלבי יעד יומיים של משתמש',
        fitnesslevel: ' רמת כושר',
        targetweight: 'משקל מטרה',
        timetosleep: 'זמן לישון',

        health: 'מידע בריאותי',
        healthneed: 'צרכים בריאותיים',
        healthissues: 'בעיות בריאות',
        healthstatus: 'מצב בריאותי',
        bloodpressure: 'לחץ דם',
        bloodsugar: 'סוכר בדם',
        medicalrecommedations: 'המלצות רפואיות',
        pregnant: 'בהריון',
        breastfeeding: 'מניקה',
        excerciselimitation: 'הגבלת פעילות גופנית',
        foodpreferences: 'העדפות תזונה',
        foodsensetivity: 'רגישות לאוכל',
        foodmethod: 'סגנון ארוחה',
        specialpreference: 'העדפת תזונה',
        vegetarian: 'צמחוני',
        vegan: 'טבעוני',
        fruity: 'פירותי',

        // nutritionistplans: 'תפריטי תזונה',
        nutritionistsubheadings: 'בואו נתחיל לחיות בריא מעכשיו',
        planname: 'שם התוכנית',
        plantags: 'תגיות',
        plangender: 'מגדר',
        planages: 'גילאים',
        planType: 'סוג תוכנית',
        addnutrisionistplan: 'הוסף/ערוך תפריט תזונה',
        addnutrisionistplanssubheadings: 'אל תשכח לשמור לאחר העדכון.',
        personalinfo: 'מידע אישי',

        female: 'נשים',
        male: 'גברים',
        other: 'אחר',
        from: 'מ',
        to: 'עד',
        plannotes: 'הערות',
        plancreation: 'תאריך יצירת התוכנית',
        eatingtime: 'זמני אוכל',
        breakfast: 'א. בוקר',
        nameoffood: 'שם המזון',
        measurementtype: 'מידה',
        gram: 'גרם',
        mealnotes: 'הערות/הגבלות לארוחה',
        afterbrekfast: 'אחרי א. בוקר',
        lunch: 'צהריים',
        afterlunch: 'אחרי צהריים',
        evening: 'ערב',
        save: 'שמירה',
        total: 'סה״כ',
        carbs: 'פחמימה',
        fat: 'שומן',

        articles: 'מאמרים',
        articlessubheading: '',
        article1: 'הבנת כולסטרול: הטוב, הרע והמכוער',
        article2: 'מה אם תוכנית התזונה שלי אינה מכוסה בביטוח?',
        article3: 'הבנת כולסטרול: הטוב, הרע והמכוער',
        article4: 'מה אם תוכנית התזונה שלי אינה מכוסה בביטוח?',

        articleedit: 'עריכת מאמר',
        articlesubheading: ' בואו נתחיל לחיות בריא מעכשיו ',
        articletitle: 'כותרת המאמר',
        content: 'תוכן',
        articleimage: 'תמונת מאמר',

        signupscaleapp: 'הירשם למערכת',

        weightLoss: 'הרזיה',
        muscleGain: 'עלייה בשרירים',
        minute: 'דקה',
        sendOtp: 'שלח otp',
        verifyOtp: 'לאמת otp',
        picture: 'תְמוּנָה',
        association: 'תוכנית תזונה עמית',
        associateBtnTxt: 'חָבֵר',
        planAssigned: 'תוכנית מוקצה',
        admins: 'מנהלי מערכת',
        trainers: 'מאמנים',
        addNewTrainer: 'הוסף מאמן חדש',
        addEditTrainer: 'הוסף/ערוך מאמן',

        addNewFood: 'הוסף רשימת מזון',
        addEditFood: 'הוסף/ערוך מזון',
        foodDetails: 'פרטי מזון',

        foodName: 'שם אוכל',
        carbsPer: 'פחמימות (ל-100 גרם)',
        fatPer: 'שומן (ל-100 גרם)',
        proteinPer: 'חלבון (ל-100 גרם)',
        caloriesPer: 'קלוריות (ל-100 גרם)',

        addNewRecipe: 'הוסף מתכון חדש',
        addEditRecipe: 'הוסף/ערוך מתכון',
        recipeDetails: 'פרטי מתכון',
        recipePhoto: 'תמונה של מתכון',
        photo: 'תַצלוּם',

        howtomake: 'איך להכין',
        ingredients: 'מרכיבים',

        foodList: 'רשימת מזון',
        deleteFoodItem: 'האם אתה בטוח שברצונך למחוק פריט מזון זה?',
        recepieTitle: 'כותרת המתכון',
        recipes: 'מתכונים',
        deleteRecipeItem: 'האם אתה בטוח שברצונך למחוק את המתכון הזה?',
      },
    },
    en: {
      translation: {
        dashboard: 'Dashboard',
        clients: 'Clients',
        addnewclient: 'Add New Client',
        nutritionistplans: 'Nutritionist Plans',
        addnewplan: 'Add New Plan',
        // articles: 'Articles',
        addnewarticle: 'Add New Article',
        logout: 'Log Out',

        dashboardtitle: 'Hello, Idan!',
        dashboardsubtitle: 'Idan Bachar’s Dashboard',
        nutrition: 'Nutrition',
        calories: 'Calories',
        protein: 'Protein',
        // fat: 'Fat',
        Carbs: 'Carbs',

        waterintake: 'Water Intake',
        glasses: 'Glasses',
        steps: 'Steps',
        sendapushmessge: 'Send a push message',
        weightprogress: 'Weight Progress',
        progress: 'Progress',
        goal: 'Goal',
        lastweight: 'Last Weight',
        caloriesburned: 'Calories Burned',
        Cal: 'cal',
        kg: 'kg',
        stepstaken: 'Step Taken',
        workoutname: 'Workout Name',
        functionalworkout: 'Functional Workout',
        activity: 'Activity',

        adminname: 'Idan Bachar',
        date: 'January 21, 2024',

        send: 'Send',
        month: 'August',
        signupscaleapp: 'Signup',
        continue: 'Continue',
        enterverficationcode: 'Enter a Verification Code',
        notrecieved: 'Didn’t receive? ',
        resend: 'Resend in ',
        privacypolicy: 'Regualtions | Privacy Policy',

        clientheader: 'Hello, Idan!',
        clientsubheader: 'Lets start living healthy from now on',

        move: 'Move',
        water: 'Water',
        // nutrition: 'Nutrition',
        weight: 'Weight',
        search: 'Search',
        selectcol: 'Select Col',

        firstname: 'First Name',
        lastname: 'Last name',
        phone: 'Phone',
        age: 'Age',
        // currentweight: 'Current Weight',
        planassigned: 'Plan Assigned',
        actions: 'Actions',
        import: 'Import',
        export: 'Export',
        showing: 'Showing 1 to 8 of 40 items',

        delete: 'Delete',
        cancel: 'Cancel',
        deleteNotification: 'Are you sure you want to delete this client?',
        deletePlanNotification: 'Are you sure you want to delete this plan?',

        addeditclient: 'Add/Edit Client',
        addclientsubheading: 'Dont forget to save after update.',
        // personalinfo: 'Personal information',
        userphoto: 'User Photo',
        status: 'Status',
        phonenumber: 'Phone Number',
        email: 'Email',
        birthdate: 'Birthdate',
        currentweight: 'Current Weight',
        height: 'Height',
        preferences: 'Preferences & Goals',
        reminders: 'Reminders',
        food: 'Food',
        dailygoalnutrition: ' User daily goal nutrition',
        dailygoalwater: ' User daily goal water',
        dailygoalactivity: ' User daily goal activity',
        dailygoalweight: ' User daily goal weight',
        dailygoalsteps: ' User daily goal steps',
        fitnesslevel: ' Fitness level',
        targetweight: 'Target weight',
        timetosleep: 'Time to sleep',

        health: 'Health Information',
        healthneed: 'Health need',
        healthissues: 'Health issues',
        healthstatus: 'Health status',
        bloodpressure: 'Blood Pressure',
        bloodsugar: 'Blood sugar',
        medicalrecommedations: 'Medication recommendations',
        pregnant: 'Pregnant',
        breastfeeding: 'Breast feeding',
        excerciselimitation: 'Exercise limitation',
        foodpreferences: 'Food preferences',
        foodsensetivity: 'Food Sensetivity',
        foodmethod: 'Food method',
        specialpreference: 'Special Preferences',
        vegetarian: 'Vegetarian',
        vegan: 'Vegan',
        fruity: 'Fruity',

        // nutritionistplans: 'Nutritionist Plans',
        nutritionistsubheadings: 'Lets start living healthy from now on',
        planname: 'Plan name',
        plantags: 'Plan Tags',
        plangender: 'Gender',
        planages: 'Plan Ages',
        planType: 'Plan Type',
        addnutrisionistplan: 'Add/Edit Nutritionist Plan',
        addnutrisionistplanssubheadings: 'Dont forget to save after update.',
        personalinfo: 'Personal information',
        female: 'Female',
        male: 'Male',
        other: 'Other',
        from: 'From',
        to: 'To',
        plannotes: 'Plan Notes',
        plancreation: 'Plan creation date',
        eatingtime: 'Eating times',
        breakfast: 'Breakfast',
        nameoffood: 'Name of food',
        measurementtype: 'Measurement type',
        gram: 'Gram',
        mealnotes: 'Meal Notes/Restrictions',
        afterbrekfast: 'After breakfast',
        lunch: 'Lunch',
        afterlunch: 'After lunch',
        evening: 'Evening',
        save: 'Save',
        total: 'Total',

        carbs: 'Carbs',
        fat: 'Fat',

        articles: 'Articles',
        articlessubheading: 'Lets start living healthy from now on',
        article1: 'Understanding Cholesterol: The Good, The Bad, and The Ugly',
        article2: 'What If My Nutrition Program is Not Covered By Insurance?',
        article3: 'Understanding Cholesterol: The Good, The Bad, and The Ugly',
        article4: 'What If My Nutrition Program is Not Covered By Insurance?',

        articleedit: 'Article Edit',
        articlesubheading: 'Lets start living healthy from now on',
        articletitle: 'Article Title',
        content: 'Content',
        articleimage: 'Article Image',

        weightLoss: 'Weight loss',
        muscleGain: 'Muscle Gain',
        minute: 'Min',
        sendOtp: 'Send Otp',
        verifyOtp: 'Verify Otp',
        picture: 'Picture',
        association: 'Associate Nutrition Plan',
        associateBtnTxt: 'Associate',
        planAssigned: 'Plan Assigned',
        admins: 'Admins',
        trainers: 'Trainers',
        addNewTrainer: 'Add New Trainer',
        addEditTrainer: 'Add/Edit Trainer',

        addNewFood: 'Add Food List',
        addEditFood: 'Add/Edit Food',
        foodDetails: 'Food Details',

        foodName: 'Food Name',

        carbsPer: 'Carbs (per 100grams)',
        fatPer: 'Fat (per 100grams)',
        proteinPer: 'Protein (per 100grams)',
        caloriesPer: 'Calories (per 100grams)',

        addNewRecipe: 'Add New Recipe',
        addEditRecipe: 'Add/Edit Recipe',
        recipeDetails: 'Recipe Details',
        recipePhoto: 'Recipe photo',
        photo: 'Photo',

        howtomake: 'How to make',
        ingredients: 'Ingredients',
        foodList: 'Food List',

        deleteFoodItem: 'Are you sure you want to delete this food item?',
        recepieTitle: 'Recipe Title',
        recipes: 'Recipes',
        deleteRecipeItem: 'Are you sure you want to delete this recipe?',
      },
    },
  },
  lng: 'he',
  fallbackLng: 'he',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
