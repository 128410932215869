import React, {useRef} from 'react';
import {MenuItem, Select} from '@mui/material';
import Switch from '@mui/material/Switch';
import Camera from '../../assets/camera.png';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import TrainerSelector from '../../components/TrainerSelector';
type Props = {
  client: any;
  setClient: (client: any) => void;
  selectedTrainerId?: any;
  setSelectedTrainerId?: (val: any) => void;
  isAddingClient?: boolean;
};
interface Option {
  label: string;
  value: string;
}
interface CheckboxProps {
  id: number;
  key: string;
  label: string;
}
interface Item {
  label: string;
  id: number;
  key: string;
  options?: Option[];
  type: string;
}
function EditClients({
  client,
  setClient,
  selectedTrainerId,
  setSelectedTrainerId,
  isAddingClient,
}: Props) {
  const {t} = useTranslation();
  const inputImgRef = useRef(null);

  const column1 = [
    {
      id: 1,
      key: 'active',
      label: t('status'),
      type: 'switch',
    },
    {
      id: 2,
      key: 'fname',
      label: t('firstname'),
      type: 'text',
    },
    {
      id: 3,
      key: 'lname',
      label: t('lastname'),
      type: 'text',
    },
    {
      id: 4,
      key: 'phone_number',
      label: t('phonenumber'),
      type: 'number',
    },
    {
      id: 12,
      key: 'email',
      label: t('email'),
      type: 'text',
    },
    {
      id: 5,
      key: 'gender',
      label: t('plangender'),
      type: 'select',
      options: [
        {label: 'Male', value: 'male'},
        {label: 'Female', value: 'female'},
      ],
    },
    {
      id: 6,
      key: 'birthdate',
      label: t('birthdate'),
      type: 'date',
    },
    {
      id: 7,
      key: 'current_weight',
      label: t('currentweight'),
      type: 'text',
    },
    {
      id: 8,
      key: 'height',
      label: t('height'),
      type: 'text',
    },
  ];

  const reminders = [
    {
      id: 1,
      label: t('water'),
      key: 'water_reminder',
    },
    {
      id: 2,
      label: t('food'),
      key: 'food_reminder',
    },
    {
      id: 3,
      label: t('steps'),
      key: 'steps_reminder',
    },
    {
      id: 4,
      label: t('activity'),
      key: 'activity_reminder',
    },
    {
      id: 5,
      label: t('weight'),
      key: 'weight_reminder',
    },
  ];

  const column2 = [
    {
      id: 1,
      key: 'user_daily_goal_nutrition',
      label: t('dailygoalnutrition'),
      type: 'text',
    },
    {
      id: 2,
      key: 'user_daily_goal_water',
      label: t('dailygoalwater'),
      type: 'text',
    },
    {
      id: 3,
      key: 'user_daily_goal_activity',
      label: t('dailygoalactivity'),
      type: 'text',
    },
    {
      id: 4,
      key: 'user_daily_goal_weight',
      label: t('dailygoalweight'),
      type: 'text',
    },
    {
      id: 5,
      key: 'user_daily_goal_steps',
      label: t('dailygoalsteps'),
      type: 'text',
    },
    {
      id: 6,
      key: 'fitness_level',
      label: t('fitnesslevel'),
      type: 'select',
      options: [
        {label: 'Good', value: 'good'},
        {label: 'Average', value: 'average'},
        {label: 'Bad', value: 'bad'},
      ],
    },
    {
      id: 7,
      key: 'target_weight',
      label: t('targetweight'),
      type: 'text',
    },
    {
      id: 8,
      key: 'time_to_sleep',
      label: t('timetosleep'),
      type: 'time',
    },
  ];

  const column3 = [
    {
      id: 1,
      key: 'health_need',
      label: t('healthneed'),
      type: 'text',
    },
    {
      id: 2,
      key: 'health_issues',
      label: t('healthissues'),
      type: 'text',
    },
    {
      id: 3,
      key: 'health_status',
      label: t('healthstatus'),
      type: 'text',
    },
    {
      id: 4,
      key: 'blood_pressure',
      label: t('bloodpressure'),
      type: 'text',
    },
    {
      id: 6,
      key: 'medical_recommend',
      label: t('medicalrecommedations'),
      type: 'text',
    },
    {
      id: 7,
      key: 'pregnant',
      label: t('pregnant'),
      type: 'text',
    },
    {
      id: 8,
      key: 'breastfeeding',
      label: t('breastfeeding'),
      type: 'text',
    },
    {
      id: 9,
      key: 'exercise_limitation',
      label: t('excerciselimitation'),
      type: 'select',
      options: [
        {label: 'Good', value: 'good'},
        {label: 'Average', value: 'average'},
        {label: 'Bad', value: 'bad'},
      ],
    },
    {
      id: 10,
      key: 'food_preferences',
      label: t('foodpreferences'),
      type: 'text',
    },
    {
      id: 11,
      key: 'food_sensitivity',
      label: t('foodsensetivity'),
      type: 'text',
    },
    {
      id: 12,
      key: 'food_common_method',
      label: t('foodmethod'),
      type: 'text',
    },
  ];

  const specialPreferences = [
    {
      id: 1,
      label: t('vegetarian'),
      key: 'is_vegetarian',
    },
    {
      id: 2,
      label: t('vegan'),
      key: 'is_vegan',
    },
    {
      id: 3,
      label: t('fruity'),
      key: 'is_fruity',
    },
  ];
  const imgSelector = () => {
    // @ts-ignore
    if (inputImgRef.current) inputImgRef.current?.click();
  };

  const handleImg = (event: React.ChangeEvent<HTMLInputElement>) => {
    //@ts-ignore
    const file = event.target.files[0];
    if (!file) return;

    if (file.size > 300 * 1024) {
      alert('File size should be less than 300KB');
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result;
      if (base64String) {
        setClient((prev: any) => ({
          ...prev,
          profile_photo: base64String,
        }));
      } else {
        console.error('Failed to convert image to base64.');
      }
    };

    reader.onerror = error => {
      console.error('Error reading file:', error);
    };

    reader.readAsDataURL(file);
  };

  const handleChange = (key: string, value: any) => {
    if (key === 'active') {
      setClient((prev: any) => {
        return {
          ...prev,
          [key]: value ? 1 : 0,
        };
      });
    } else if (key === 'birthdate') {
      setClient((prev: any) => {
        const date = new Date(value);
        return {
          ...prev,
          [key]: date?.toISOString(),
        };
      });
    } else {
      setClient((prev: any) => {
        return {
          ...prev,
          [key]: value,
        };
      });
    }
  };
  const CustomCheckbox = ({item}: {item: CheckboxProps}) => {
    return (
      <label className="custom-checkbox">
        <input
          type="checkbox"
          checked={client[item.key]}
          onChange={e => handleChange(item.key, e.target.checked ? 1 : 0)}
        />
        <span className="checkmark"></span>
        <p className="text-xs font-[400] text-dark-text">{item.label}</p>
      </label>
    );
  };
  return (
    <div className="border border-border-color rounded-[15px] grid grid-cols-3 mt-4 p-6 gap-24">
      {/* column 1 */}
      <div className="flex w-full">
        <div className="w-fit px-4">
          {client.profile_photo ? (
            <div
              onClick={imgSelector}
              className="h-[80px] w-[80px] overflow-hidden rounded-full flex items-center justify-center">
              <img
                src={client.profile_photo}
                alt="profile-pic"
                className="h-full w-full object-cover"
              />
            </div>
          ) : (
            <div
              onClick={imgSelector}
              className="min-h-[80px] min-w-[80px] w-fit bg-gray-600 rounded-full flex items-center flex-col justify-center">
              <img src={Camera} alt="camera" />
              <p className="text-xs font-[400] mt-1 text-white">
                {t('userphoto')}
              </p>
            </div>
          )}

          <input
            ref={inputImgRef}
            className="hidden"
            type="file"
            onChange={handleImg}
            accept=".png, .jpg, .jpeg"
          />
        </div>
        <div className="w-full">
          <h2 className="h2 font-medium text-dark-text">{t('personalinfo')}</h2>
          {column1.map(item => (
            <div key={item.id} className="mt-4">
              <p className="text-xs font-[400] text-dark-text leading-4 mb-1">
                {item.label}
              </p>
              {item.type === 'text' ||
              item.type === 'number' ||
              item.type === 'time' ||
              item.type === 'date' ? (
                <input
                  type={item.type}
                  name={item.key}
                  value={
                    item.type === 'date'
                      ? moment(new Date(client[item.key])).format('YYYY-MM-DD')
                      : client[item.key]
                  }
                  onChange={e => handleChange(item.key, e.target.value)}
                  className="w-full h-[40px] rounded-[40px] border-[1px] border-border-color px-4 text-xs focus:outline-none focus:ring-1 focus:ring-[#7ECD93] focus:border-[#7ECD93]"
                />
              ) : item.type === 'select' ? (
                <Select
                  name={item.key}
                  value={client[item.key]}
                  onChange={(e: any) => handleChange(item.key, e.target.value)}
                  sx={{
                    width: '100%',
                    height: '42px',
                    borderRadius: '40px',
                    backgroundColor: '#ffffff',
                    border: '1px solid #E7E7E7',
                  }}>
                  {item.options?.map((option: any) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              ) : item.type === 'switch' ? (
                <Switch
                  size="medium"
                  checked={client[item.key]}
                  onChange={(e: any) =>
                    handleChange(item.key, e.target.checked)
                  }
                  sx={{
                    height: '40px',
                    width: '60px',
                    '& .MuiSwitch-thumb': {
                      backgroundColor: '#7ECD93',
                    },
                    '&.Mui-checked .MuiSwitch-thumb': {
                      backgroundColor: '#7ECD93',
                    },
                  }}
                />
              ) : null}
            </div>
          ))}
          {isAddingClient && localStorage.getItem('user_type') === '1' && (
            <div className="mt-4">
              <p className="text-xs font-[400] text-dark-text leading-4 mb-1">
                {t('trainers')}
              </p>
              <TrainerSelector
                selectedTrainerId={selectedTrainerId}
                {...(setSelectedTrainerId ? {setSelectedTrainerId} : {})}
                isShowLabel={false}
                width={'100%'}
              />
            </div>
          )}
        </div>
      </div>
      {/* column 2 */}
      <div>
        <h2 className="h2 font-medium text-dark-text">{t('preferences')}</h2>
        {/* Reminders section */}
        <div className="mt-4">
          <p className="text-xs font-[400] text-dark-text leading-4 mb-1">
            {t('reminders')}
          </p>
          <div className="flex flex-wrap flex-row items-center gap-4 py-2">
            {reminders.map(item => (
              <CustomCheckbox key={item.id} item={{...item, key: item.key}} /> // Pass key
            ))}
          </div>
        </div>
        {column2.map(item => (
          <div key={item.id} className="mt-4">
            <p className="text-xs font-[400] text-dark-text leading-4 mb-1">
              {item.label}
            </p>
            {item.type === 'text' ||
            item.type === 'number' ||
            item.type === 'time' ||
            item.type === 'date' ? (
              <input
                type={item.type}
                name={item.key}
                value={client[item.key]}
                onChange={e => handleChange(item.key, e.target.value)}
                className="w-full h-[40px] rounded-[40px] border-[1px] border-border-color px-4 text-xs focus:outline-none focus:ring-1 focus:ring-[#7ECD93] focus:border-[#7ECD93]"
              />
            ) : item.type === 'select' ? (
              <Select
                name={item.key}
                value={client[item.key]}
                onChange={(e: any) => handleChange(item.key, e.target.value)}
                sx={{
                  width: '100%',
                  height: '42px',
                  borderRadius: '40px',
                  backgroundColor: '#ffffff',
                  border: '1px solid #E7E7E7',
                }}>
                {item.options?.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            ) : item.type === 'switch' ? (
              <Switch
                checked={client[item.key]}
                onChange={(e: any) => handleChange(item.key, e.target.checked)}
                sx={{
                  height: '40px',
                  width: '60px',
                  '& .MuiSwitch-thumb': {
                    backgroundColor: '#7ECD93',
                  },
                  '&.Mui-checked .MuiSwitch-thumb': {
                    backgroundColor: '#7ECD93',
                  },
                }}
              />
            ) : null}
          </div>
        ))}
      </div>
      {/* column 3 */}
      <div>
        <h2 className="h2 font-medium text-dark-text">{t('health')}</h2>
        {column3.map(item => (
          <div key={item.id} className="mt-4">
            <p className="text-xs font-[400] text-dark-text leading-4 mb-1">
              {item.label}
            </p>
            {item.type === 'text' ||
            item.type === 'number' ||
            item.type === 'time' ||
            item.type === 'date' ? (
              <input
                type={item.type}
                name={item.key}
                value={client[item.key]}
                onChange={e => handleChange(item.key, e.target.value)}
                className="w-full h-[40px] rounded-[40px] border-[1px] border-border-color px-4 text-xs focus:outline-none focus:ring-1 focus:ring-[#7ECD93] focus:border-[#7ECD93]"
              />
            ) : item.type === 'select' ? (
              <Select
                name={item.key}
                value={client[item.key]}
                onChange={(e: any) => handleChange(item.key, e.target.value)}
                sx={{
                  width: '100%',
                  height: '42px',
                  borderRadius: '40px',
                  backgroundColor: '#ffffff',
                  border: '1px solid #E7E7E7',
                }}>
                {item.options?.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            ) : item.type === 'switch' ? (
              <Switch
                checked={client[item.key]}
                onChange={(e: any) => handleChange(item.key, e.target.checked)}
                sx={{
                  height: '40px',
                  width: '60px',
                  '& .MuiSwitch-thumb': {
                    backgroundColor: '#7ECD93',
                  },
                  '&.Mui-checked .MuiSwitch-thumb': {
                    backgroundColor: '#7ECD93',
                  },
                }}
              />
            ) : null}
          </div>
        ))}
        {/* checkboxes container */}
        <div className="mt-4">
          <p className="text-xs font-[400] text-dark-text leading-4 mb-1">
            {t('specialpreference')}
          </p>
          <div className="flex flex-wrap flex-row items-center gap-4 py-2">
            {specialPreferences?.map(item => (
              <CustomCheckbox key={item.id} item={{...item, key: item.key}} /> // Pass key
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditClients;
