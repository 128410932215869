import moment from 'moment';
import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
type Props = {weightEntries: any};

const data = [
  {
    name: '1.9.24',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: '2.9.24',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: '2.9.24',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: '4.9.24',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
];
function CustomLineChart({weightEntries}: Props) {
  // Check if weight entries are available
  if (weightEntries.length === 0) {
    return null;
  }

  // Map the weight entries to the format needed by the chart
  const chartData = weightEntries.map((entry: any) => ({
    name: moment(entry.datetime).format('DD/MM/YY'), // Format datetime as DD/MM/YY
    weight: entry.lifestyle_value, // lifestyle_value to be shown on Y axis
  }));

  return (
    <div className="h-[72%] w-full mt-10">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={chartData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}>
          <XAxis
            dataKey="name"
            axisLine={false}
            tickLine={false}
            tick={{fontSize: '14px', fontWeight: '400'}}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            tickMargin={30}
            tickCount={4}
            tick={{fill: '#9C9EB9', fontSize: '14px', fontWeight: '600'}}
          />
          <Tooltip />
          <Line
            type="monotone"
            dataKey="weight"
            stroke="#7ECD93"
            activeDot={{r: 6}}
            strokeWidth={1.5}
            dot={false}
          />

          {/* <Line
            type="monotone"
            dataKey="pv"
            stroke="#7ECD93"
            activeDot={{r: 6}}
            strokeWidth={1.5}
            dot={false}
          />
          <Line
            type="monotone"
            dataKey="uv"
            stroke="#000000"
            dot={false}
            strokeWidth={1.5}
          /> */}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default CustomLineChart;
