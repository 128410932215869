import React, {useEffect, useState} from 'react';
import {CheckboxProps, Gender} from '../../types';
import {ages, measurements, planTags} from '../../constants';
import {Autocomplete, MenuItem, Select, TextField} from '@mui/material';
import {useTranslation} from 'react-i18next';
import CircularProgress from '../../components/CircularProgress';
import moment from 'moment';
import TrainerSelector from '../../components/TrainerSelector';
type Props = {
  nutritionist: any;
  setNutritionist: (editNutrisionistPlans: any) => void;
  selectedTrainerId: any;
  setSelectedTrainerId: (val: any) => void;
  foodIngredients?: any[];
};

type EatingKey = 'breakfast' | 'afterBreakfast' | 'lunch' | 'afterLunch';
function EditNutrisionistPlans({
  nutritionist,
  setNutritionist,
  selectedTrainerId,
  setSelectedTrainerId,
  foodIngredients,
}: Props) {
  const {t} = useTranslation();
  const [totalNutrition, setTotalNutrition] = useState({
    calories: 0,
    carbs: 0,
    fat: 0,
    protein: 0,
  });

  const UNIT_CONVERSIONS = {
    gram: 1,
    spoon: 5,
    glass: 200,
    unit: 50,
  };

  useEffect(() => {
    const tranformedData = dataTransformation();
    const totals = calculateTotalNutrition(tranformedData);
    setTotalNutrition(totals);
  }, [nutritionist.eating_times, foodIngredients]);

  const dataTransformation = () => {
    const tranformedData = nutritionist?.eating_times.map((item: any) => {
      return {
        quantity: 1,
        unit: item.measurement_type,
        food: foodIngredients?.find(
          (food: any) => food.id === item.ingredient_id,
        ),
      };
    });

    return tranformedData ?? [];
  };

  function calculateTotalNutrition(foodSelections: any) {
    let totals = {
      calories: 0,
      carbs: 0,
      fat: 0,
      protein: 0,
    };

    foodSelections.forEach((selection: any) => {
      const {food, quantity, unit} = selection;
      //@ts-ignore
      const conversionFactor = UNIT_CONVERSIONS[unit] || 1;
      const grams = (quantity * conversionFactor) / 100;

      totals.calories += food?.item_calories_per100g * grams || 0;
      totals.carbs += food?.item_carbs_per100g * grams || 0;
      totals.fat += food?.item_fat_per100g * grams || 0;
      totals.protein += food?.item_protein_per100g * grams || 0;
    });

    return totals;
  }
  const planTypes = [
    {
      id: 1,
      label: t('weightLoss'),
      key: 'weightLoss',
      type: 'plan_type',
    },
    {
      id: 2,
      label: t('muscleGain'),
      key: 'muscleGain',
      type: 'plan_type',
    },
    {
      id: 3,
      label: t('other'),
      key: 'otherPlanTypes',
      type: 'plan_type',
    },
  ];

  const eatingTimes = [
    {
      id: 1,
      label: t('breakfast'),
      key: 'breakfast',
      type: 'eating_times',
    },
    {
      id: 2,
      label: t('afterbrekfast'),
      key: 'afterBreakfast',
      type: 'eating_times',
    },
    {
      id: 3,
      label: t('lunch'),
      key: 'lunch',
      type: 'eating_times',
    },
    {
      id: 4,
      label: t('afterlunch'),
      key: 'afterLunch',
      type: 'eating_times',
    },
  ];

  const cardData = [
    {
      id: 1,
      title: t('protein'),
      value: '206g',
    },
    {
      id: 2,
      title: t('carbs'),
      value: '106g',
    },
    {
      id: 3,
      title: t('fat'),
      value: '46g',
    },
  ];

  const genders: Gender[] = [
    {
      id: 1,
      label: t('male'),
      key: 'male',
      type: 'plan_gender',
    },
    {
      id: 2,
      label: t('female'),
      key: 'female',
      type: 'plan_gender',
    },
    {
      id: 3,
      label: t('other'),
      key: 'otherGender',
      type: 'plan_gender',
    },
  ];
  const handleChange = (key: string, value: any, type?: string) => {
    if (type === 'plan_type' || type === 'plan_gender') {
      if (nutritionist[type].includes(key)) {
        setNutritionist((prev: any) => {
          return {
            ...prev,
            [type]: prev[type].filter((item: string) => item !== key),
          };
        });
      } else {
        setNutritionist((prev: any) => {
          return {
            ...prev,
            [type]: [...prev[type], key],
          };
        });
      }
      return;
    }
    if (type === 'plan_ages') {
      setNutritionist((prev: any) => {
        const existingRange = prev.plan_ages[0] || {min: '', max: ''};
        const newRange = {
          min: key === 'min' ? value : existingRange.min,
          max: key === 'max' ? value : existingRange.max,
        };
        if (newRange.min && newRange.max) {
          return {
            ...prev,
            plan_ages: [newRange],
          };
        }
        return {
          ...prev,
          plan_ages: [newRange],
        };
      });
      return;
    }
    setNutritionist((prev: any) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };
  const handleEatingTimes = (key: EatingKey) => {
    setNutritionist((prev: any) => {
      const currentTimes = prev.eating_times || []; // Ensure it's an array
      const existingEntryIndex = currentTimes.findIndex(
        (entry: any) => entry.time_name === key,
      );

      if (existingEntryIndex !== -1) {
        return {
          ...prev,
          eating_times: currentTimes.filter(
            //@ts-ignore
            (_, index) => index !== existingEntryIndex,
          ),
        };
      } else {
        // Otherwise, add a new eating time entry
        return {
          ...prev,
          eating_times: [
            ...currentTimes,
            {
              time_name: key,
              measurement_type: '',
              meal_notes: '',
              ingredient_id: null,
            },
          ],
        };
      }
    });
  };

  const CustomCheckbox = ({item}: {item: CheckboxProps}) => {
    return (
      <label className="custom-checkbox">
        <input
          type="checkbox"
          checked={
            //@ts-ignore
            nutritionist[item.type]?.includes(item.key) || false
          }
          onChange={e => {
            handleChange(item.key, e.target.checked, item.type);
          }}
        />
        <span className="checkmark"></span>
        <p className="text-xs font-[400] text-dark-text">{item.label}</p>
      </label>
    );
  };
  return (
    <div className="border border-border-color rounded-[15px] mt-4 p-6">
      <h2 className="h2 font-medium text-dark-text">{t('personalinfo')}</h2>

      <div className="grid grid-cols-3 gap-24 mt-2">
        {/* column 1 */}
        <div className="flex flex-col gap-4">
          <div>
            <p className="text-xs font-[400] text-dark-text leading-4 mb-1">
              {t('planname')}
            </p>
            <input
              type="text"
              name="plan_name"
              value={nutritionist?.plan_name || ''}
              onChange={e => handleChange('plan_name', e.target.value)}
              className="w-full h-[40px] rounded-[40px] border-[1px] border-border-color px-4 text-xs focus:outline-none focus:ring-1 focus:ring-[#7ECD93] focus:border-[#7ECD93]"
            />
          </div>
          <div>
            <p className="text-xs font-[400] text-dark-text leading-4 mb-1">
              {t('plangender')}
            </p>
            <div className="flex flex-col  gap-4 py-2">
              {genders?.map(item => (
                <CustomCheckbox key={item.id} item={{...item, key: item.key}} />
              ))}
            </div>
          </div>
          <div>
            <p className="text-xs font-[400] text-dark-text leading-4 mb-1">
              {t('planages')}
            </p>
            <div className="flex flex-row  items-center gap-4 py-2">
              <p className="text-xs font-[400] text-dark-text leading-4 mb-1">
                {t('from')}
              </p>
              <Select
                name="max"
                value={nutritionist?.plan_ages[0]?.max || ''}
                onChange={(e: any) =>
                  handleChange('max', e.target.value, 'plan_ages')
                }
                sx={{
                  width: '80px',
                  height: '42px',
                  borderRadius: '40px',
                  backgroundColor: '#ffffff',
                  border: '1px solid #E7E7E7',
                }}>
                {ages?.map((option: string) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
              <p className="text-xs font-[400] text-dark-text leading-4 mb-1">
                {t('to')}
              </p>
              <Select
                name="min"
                value={nutritionist?.plan_ages[0]?.min || ''}
                onChange={(e: any) =>
                  handleChange('min', e.target.value, 'plan_ages')
                }
                sx={{
                  width: '80px',
                  height: '42px',
                  borderRadius: '40px',
                  backgroundColor: '#ffffff',
                  border: '1px solid #E7E7E7',
                }}>
                {ages?.map((option: string) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
          <div>
            <p className="text-xs font-[400] text-dark-text leading-4 mb-1">
              {t('planType')}
            </p>
            <div className="flex flex-col  gap-4 py-2">
              {planTypes?.map(item => (
                <CustomCheckbox key={item.id} item={{...item, key: item.key}} />
              ))}
            </div>
          </div>
          <div>
            <p className="text-xs font-[400] text-dark-text leading-4 mb-1">
              {t('plantags')}
            </p>
            <Select
              name="plan_tags"
              multiple
              value={nutritionist?.plan_tags || []}
              onChange={e => handleChange('plan_tags', e.target.value)}
              sx={{
                width: '100%',
                height: '42px',
                borderRadius: '40px',
                backgroundColor: '#ffffff',
                border: '1px solid #E7E7E7',
              }}>
              {planTags?.map((option: string) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div>
            <p className="text-xs font-[400] text-dark-text leading-4 mb-1">
              {t('plannotes')}
            </p>
            <input
              type="text"
              name="plan_notes"
              value={nutritionist?.plan_notes}
              onChange={e => handleChange('plan_notes', e.target.value)}
              className="w-full h-[40px] rounded-[40px] border-[1px] border-border-color px-4 text-xs focus:outline-none focus:ring-1 focus:ring-[#7ECD93] focus:border-[#7ECD93]"
            />
          </div>
          <div>
            <p className="text-xs font-[400] text-dark-text leading-4 mb-1">
              {t('plancreation')}
            </p>
            <input
              type="date"
              name="plan_creation_date"
              value={
                nutritionist?.plan_creation_date
                  ? moment(nutritionist.plan_creation_date).format('YYYY-MM-DD')
                  : ''
              }
              onChange={e =>
                handleChange(
                  'plan_creation_date',
                  new Date(e.target.value)?.toISOString(),
                )
              }
              className="w-full h-[40px] rounded-[40px] border-[1px] border-border-color px-4 text-xs focus:outline-none focus:ring-1 focus:ring-[#7ECD93] focus:border-[#7ECD93]"
            />
          </div>
        </div>
        {/* column 2 */}
        <div className="flex flex-col gap-4">
          <div>
            <p className="text-xs font-[400] text-dark-text leading-4 mb-1">
              {t('eatingtime')}
            </p>
            <div className="flex flex-col gap-4 mt-4">
              {eatingTimes?.map(item => {
                const isChecked = nutritionist.eating_times?.some(
                  (entry: any) => entry.time_name === item.key,
                );
                const obj = nutritionist.eating_times?.find(
                  (entry: any) => entry.time_name === item.key,
                );
                const finalObj = foodIngredients?.find(
                  (food: any) => food.id === obj?.ingredient_id,
                );

                return (
                  <div key={item.id}>
                    <label className="custom-checkbox">
                      <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={() =>
                          handleEatingTimes(item.key as EatingKey)
                        }
                      />
                      <span className="checkmark"></span>
                      <p className="text-xs font-[400] text-dark-text">
                        {item.label}
                      </p>
                    </label>

                    {isChecked && (
                      <div className="pl-8 flex flex-col gap-2 mt-2">
                        <div className="flex flex-col gap-1">
                          <p className="text-xs font-[400] text-dark-text leading-4 mb-1">
                            {t('nameoffood')}
                          </p>
                          <Autocomplete
                            disablePortal
                            size="small"
                            value={finalObj?.item_name || ''}
                            options={
                              foodIngredients
                                ? foodIngredients.map(food => food.item_name)
                                : []
                            }
                            onChange={(event, newValue) => {
                              const selectedIngredient =
                                foodIngredients &&
                                foodIngredients.find(
                                  food => food.item_name === newValue,
                                );
                              const ingredientId = selectedIngredient
                                ? selectedIngredient.id
                                : null;

                              setNutritionist((prevNutritionist: any) => ({
                                ...prevNutritionist,
                                eating_times: prevNutritionist.eating_times.map(
                                  (entry: any) =>
                                    entry.time_name === item.key
                                      ? {...entry, ingredient_id: ingredientId}
                                      : entry,
                                ),
                              }));
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                variant="outlined"
                                sx={{
                                  width: '100%',
                                  '& .MuiOutlinedInput-root': {
                                    borderRadius: '100px',
                                    border: 'none',
                                    backgroundColor: 'white',
                                  },
                                }}
                              />
                            )}
                          />
                        </div>
                        <div className="flex flex-col gap-1">
                          <p className="text-xs font-[400] text-dark-text leading-4 mb-1">
                            {t('measurementtype')}
                          </p>
                          <Select
                            name="measurement_type"
                            value={
                              nutritionist.eating_times?.find(
                                (entry: any) => entry.time_name === item.key,
                              )?.measurement_type || ''
                            }
                            onChange={e => {
                              setNutritionist((prevNutritionist: any) => ({
                                ...prevNutritionist,
                                eating_times: prevNutritionist.eating_times.map(
                                  (entry: any) =>
                                    entry.time_name === item.key
                                      ? {
                                          ...entry,
                                          measurement_type: e.target.value,
                                        }
                                      : entry,
                                ),
                              }));
                            }}
                            sx={{
                              width: '100%',
                              height: '42px',
                              borderRadius: '40px',
                              backgroundColor: '#ffffff',
                              border: '1px solid #E7E7E7',
                            }}>
                            {measurements?.map(option => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                        <div className="flex flex-col gap-1">
                          <p className="text-xs font-[400] text-dark-text leading-4 mb-1">
                            {t('mealnotes')}
                          </p>
                          <textarea
                            rows={6}
                            className="w-full rounded-[4px] border-[1px] border-border-color px-2 py-2 text-md focus:outline-none focus:ring-1 focus:ring-[#7ECD93] focus:border-[#7ECD93]"
                            value={
                              nutritionist.eating_times?.find(
                                (entry: any) => entry.time_name === item.key,
                              )?.meal_notes || ''
                            }
                            onChange={e => {
                              setNutritionist((prevNutritionist: any) => ({
                                ...prevNutritionist,
                                eating_times: prevNutritionist.eating_times.map(
                                  (entry: any) =>
                                    entry.time_name === item.key
                                      ? {...entry, meal_notes: e.target.value}
                                      : entry,
                                ),
                              }));
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* column 3 */}
        <div className="flex flex-col gap-8">
          {localStorage.getItem('user_type') === '1' && (
            <div>
              <p className="text-xs font-[400] text-dark-text mb-2">
                {t('trainers')}
              </p>
              <TrainerSelector
                selectedTrainerId={selectedTrainerId}
                setSelectedTrainerId={setSelectedTrainerId}
                width={392}
                isShowLabel={false}
              />
            </div>
          )}

          <div className="bg-card-bg bg-opacity-10 p-4 max-w-[392px] px-4 h-[362px] flex flex-col gap-4 items-center justify-center rounded-[12px]">
            <CircularProgress cal={totalNutrition.calories ?? ''} />
            <div className="flex items-center justify-center gap-3 xl:gap-8">
              <div className="flex flex-col flex-1 gap-1 items-start justify-between">
                <p className="text-[16px] font-[400] text-dark-text">
                  {t('protein')},
                </p>
                <p className="text-[28px] font-[500] text-dark-text">
                  {Math.round(totalNutrition.protein) ?? '0'}
                </p>
              </div>
              <div className="flex flex-col flex-1 gap-1 items-start justify-between">
                <p className="text-[16px] font-[400] text-dark-text">
                  {t('carbs')}
                </p>
                <p className="text-[28px] font-[500] text-dark-text">
                  {Math.round(totalNutrition.carbs) ?? '0'}
                </p>
              </div>
              <div className="flex flex-col flex-1 gap-1 items-start justify-between">
                <p className="text-[16px] font-[400] text-dark-text">
                  {t('fat')}
                </p>
                <p className="text-[28px] font-[500] text-dark-text">
                  {Math.round(totalNutrition.fat) ?? '0'}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditNutrisionistPlans;
