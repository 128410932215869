import React, {useState, useEffect} from 'react';
import Button from '../../components/Button';
import Logo from '../../components/Logo';
import ScaleImageIcon from '../../assets/scaleImage.png';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {MuiOtpInput} from 'mui-one-time-password-input';
import {useTranslation} from 'react-i18next';
import {routes} from '../../constants/routes';
import {api} from '../../services/axiosInstance';
import {CircularProgress} from '@mui/material';

type Props = {
  setIsAuthenticated: (val: boolean) => void;
};

function Index({setIsAuthenticated}: Props) {
  const {t} = useTranslation();
  const [phone, setPhone] = useState({phone_number: ''});
  const [currentStatus, setCurrentStatus] = useState<'sendOtp' | 'verifyOtp'>(
    'sendOtp',
  );
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [resendTimer, setResendTimer] = useState(60);
  const [isTimerActive, setIsTimerActive] = useState(false);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (isTimerActive) {
      interval = setInterval(() => {
        setResendTimer(prev => {
          if (prev <= 1) {
            setIsTimerActive(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isTimerActive]);

  const handleChange = (value: string) => {
    setPhone({phone_number: '+' + value});
    // setPhone({phone_number: value});
  };

  const handleOtpChange = (newValue: string) => {
    setOtp(newValue);
  };

  const handlePhoneSubmit = async () => {
    if (phone.phone_number.length < 7) {
      return alert('Please enter a valid phone number');
    }
    setLoading(true);
    try {
      const response = await api.post(routes.SEND_OTP, phone);
      if (response.status === 200 && response.data.otp) {
        setCurrentStatus('verifyOtp');
        setIsTimerActive(true);
        setResendTimer(60);
        console.log(response.data.otp);
        setOtp(response.data.otp);
      }
    } catch (err) {
      console.error('Error sending OTP', err);
      alert('Error sending OTP');
    } finally {
      setLoading(false);
    }
  };

  const handleOtpSubmit = async () => {
    setLoading(true);
    try {
      const response = await api.post(routes.LOGIN, {
        phone_number: phone.phone_number,
        otp,
      });
      if (
        response.status === 200 &&
        response.data.token &&
        response.data.user
      ) {
        localStorage.setItem('userToken', response.data.token);
        if (response.data.user.user_type === 1) {
          localStorage.setItem('isSuperAdmin', JSON.stringify(true));
        }
        console.log(response.data.user);
        localStorage.setItem('user_type', response.data.user?.user_type);
        setIsAuthenticated(true);
      }
    } catch (err) {
      console.error('Error verifying OTP', err);
      alert('Error verifying OTP');
    } finally {
      setLoading(false);
    }
  };

  const handleResend = () => {
    if (!isTimerActive) {
      handlePhoneSubmit();
    }
  };

  return (
    <div className="h-screen w-full bg-custom-gradient">
      <Logo />
      <div className="flex flex-col items-center justify-center h-auto space-y-4">
        <h1 className="text-2xl text-heading-color tracking-wide leading-loose font-medium pb-2">
          {t('signupscaleapp')}
        </h1>

        <div className="flex flex-col items-center justify-center space-y-36">
          {currentStatus === 'sendOtp' && (
            <PhoneInput
              country="us"
              value={phone.phone_number}
              onChange={handleChange}
            />
          )}

          {currentStatus === 'verifyOtp' && (
            <div className="flex flex-col items-center gap-6">
              <MuiOtpInput
                value={otp}
                onChange={handleOtpChange}
                className="unique-otp-input-class"
              />
              <p className="font-[400] text-sm text-gray-text">
                {t('notrecieved')}
                {isTimerActive ? (
                  <span className="text-custom-green">
                    {' '}
                    {t('resend')} {`00:${String(resendTimer).padStart(2, '0')}`}
                  </span>
                ) : (
                  <span
                    className="text-custom-green cursor-pointer"
                    onClick={handleResend}>
                    {t('resend')}
                  </span>
                )}
              </p>
            </div>
          )}

          <Button
            onClick={
              currentStatus === 'sendOtp' ? handlePhoneSubmit : handleOtpSubmit
            }
            label={
              loading ? (
                <CircularProgress size={25} sx={{color: 'white'}} />
              ) : currentStatus === 'sendOtp' ? (
                t('sendOtp')
              ) : (
                t('verifyOtp')
              )
            }
            className="text-sm"
          />
        </div>

        <div className="pl-12 py-4">
          <img
            src={ScaleImageIcon}
            alt="Scale Image"
            className="mt-2 mx-auto"
          />
        </div>
        <h1 className="text-[20px] font-medium text-dark-text">
          {t('privacypolicy')}
        </h1>
      </div>
    </div>
  );
}

export default Index;
